import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import style from "../styles/sidebar.module.css";
import { logout } from "../features/userSlice";
import useFetch from "../hooks/useFetch";
import { useTranslation } from "react-i18next";
import MiPerfil from "./Portals/MiPerfil";
import { CompanyContext } from "../features/CompanyContext";

function SideBar() {
  const { t } = useTranslation();
  const userInfo = useSelector((state) => state.user.info);
  const userStatus = useSelector((state) => state.user.status);
  const [mostrarPerfil, setMostrarPerfil] = useState(false);
  const nombreCompleto = userInfo ? `${userInfo.nombre} ${userInfo.apellido}` : null;
  const [currentPage, setCurrentPage] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setCurrentPage(location.pathname);
    
    if (userStatus === "failed" && !userInfo) {
      navigate("/login");
    }
  }, [location, userInfo, userStatus, navigate]);

  return (
    <div className={style.sidebar}>
      <div className={style.infoPaginas}>
        <div className={style.infoEmpresa}>
          <img src='/img/logo8.png' alt="Logo Empresa" />
        </div>
        <div className={style.paginas}>
          <Link
            to="/home"
            className={`${style.pagina} ${currentPage === "/home" ? `${style.activePage}` : ""}`}
          >
            <svg xmlns="http://www.w3.org/2000/svg" className={currentPage === "/home" ? style.svgActivo : style.svgInactivo} height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M240-200h120v-240h240v240h120v-360L480-740 240-560v360Zm-80 80v-480l320-240 320 240v480H520v-160h-80v160H160Zm320-350Z"/></svg>
            <p className={currentPage === "/home" ? style.active : ''}>{t("sidebar-inicio")}</p>
          </Link>

          {/* Renderizar enlace a empresas solo si el rol no es "responsable" o "ambos" */}
          {userInfo && userInfo.rol !== "responsable" && userInfo.rol !== "ambos" && (
            <Link
              to="/empresas"
              className={`${style.pagina} ${currentPage === "/empresas" ? `${style.activePage} ${style.estadis}` : style.estadis}`}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" fill="#5f6368" className={currentPage === "/empresas" ? style.svgActivo : style.svgInactivo}><path d="M120-120v-560h160v-160h400v320h160v400H520v-160h-80v160H120Zm80-80h80v-80h-80v80Zm0-160h80v-80h-80v80Zm0-160h80v-80h-80v80Zm160 160h80v-80h-80v80Zm0-160h80v-80h-80v80Zm0-160h80v-80h-80v80Zm160 320h80v-80h-80v80Zm0-160h80v-80h-80v80Zm0-160h80v-80h-80v80Zm160 480h80v-80h-80v80Zm0-160h80v-80h-80v80Z"/></svg>
              <p className={currentPage === "/empresas" ? style.active : ''}>{t("sidebar-company")}</p>
            </Link>
          )}
        </div>
      </div>
      <SidebarFooter
        logoutIcon="/img/sidebar/logout.svg"
        profileIcon={userInfo?.img_profile}
        name={nombreCompleto}
        userRole={userInfo?.rol}
      />
      {mostrarPerfil && <MiPerfil cerrarPopup={() => setMostrarPerfil(false)} />}
    </div>
  );
}

export default SideBar;

function SidebarFooter({ profileIcon, name, userRole }) {
  const { t } = useTranslation();
  const { error, execute } = useFetch();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [mostrarPerfil, setMostrarPerfil] = useState(false);
  const { selectedCompany, setSelectedCompany, setSelectedCompanyId } = useContext(CompanyContext);

  const togglePerfil = () => {
    setMostrarPerfil(!mostrarPerfil);
  };

  const handleCompanyClick = () => {
    setSelectedCompany(null);
    setSelectedCompanyId(null);
    sessionStorage.removeItem("selectedCompany");
    sessionStorage.removeItem("selectedCompanyId");
  };

  async function handleLogout() {
    const apiURL = process.env.REACT_APP_API_URL || "https://api.systemicsurvey.com";
    const response = await execute(`${apiURL}/auth/logout`, "POST");
    if (response && response.status === 200) {
      sessionStorage.removeItem("selectedCompany");
      sessionStorage.removeItem("selectedCompanyId");
      setSelectedCompany(null);
      setSelectedCompanyId(null);
      dispatch(logout());
      navigate("/login");
    } else {
      console.error("Error al cerrar sesión:", error);
    }
  }

  return (
    <div className={style.footer}>
      {/* Renderizar selectedCompany solo si el rol no es "responsable" o "ambos" */}
      {userRole !== "responsable" && userRole !== "ambos" && selectedCompany && (
        <div className={style.selectedCompany} onClick={handleCompanyClick}>
          <p>{selectedCompany}</p>
        </div>
      )}

      <div className={style.perfil} id={style.mobileLogout} onClick={handleLogout}>
        <svg xmlns="http://www.w3.org/2000/svg" fill="#b2b2b2" viewBox="0 -960 960 960"><path d="M224.615-160Q197-160 178.5-178.5 160-197 160-224.615v-510.77Q160-763 178.5-781.5 197-800 224.615-800h236.154q8.539 0 14.269 5.731 5.731 5.73 5.731 14.269 0 8.538-5.731 14.269-5.73 5.731-14.269 5.731H224.615q-9.23 0-16.923 7.692Q200-744.615 200-735.385v510.77q0 9.23 7.692 16.923Q215.385-200 224.615-200h236.154q8.539 0 14.269 5.731 5.731 5.731 5.731 14.269 0 8.539-5.731 14.269-5.73 5.731-14.269 5.731H224.615Zm497.769-300H387.692q-8.538 0-14.269-5.731-5.731-5.73-5.731-14.269t5.731-14.269Q379.154-500 387.692-500h334.692l-78.846-78.846q-5.615-5.616-6-13.539-.384-7.923 6-14.538 6.385-6.616 14.154-6.731 7.769-.116 14.385 6.5l104.539 104.539q9.692 9.692 9.692 22.615 0 12.923-9.692 22.615L672.077-352.846q-5.846 5.846-13.885 6.116-8.038.269-14.654-6.347-6.384-6.615-6.269-14.269.115-7.654 6.5-14.039L722.384-460Z"/></svg>
        <Link onClick={handleLogout}>{t("sidebar-logout")}</Link>
      </div>

      {/* Renderizar imagen de perfil solo si el rol no es "responsable" o "ambos" */}
        <div className={style.perfil} onClick={togglePerfil}>
      {userRole !== "responsable" && userRole !== "ambos" && (
          <img className={style.imgProfile} src={profileIcon} alt="" />
        )}
          <p className={style.miperfil}>{name}</p>
        </div>

      <div className={style.logofeel}>
        <p>Powered by</p>
        <img className={style.logoLogin} src="/img/logo_largo.png" alt="Logo" />
      </div>

      {mostrarPerfil && <MiPerfil cerrarPopup={() => setMostrarPerfil(false)} />}
    </div>
  );
}
