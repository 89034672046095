import React, { useState } from "react";
import ReactDOM from "react-dom";
import styles from "../../styles/crearEmpresaPopup.module.css";
import { useTranslation } from "react-i18next";
import { SuccessPopup } from "../Popup";

const EliminarEquipoPopup = ({ onClose, onDelete, companyName }) => {
  const [succesDeleteTeam, setSuccesDeleteTeam] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [errors, setErrors] = useState({});
  const { t } = useTranslation();

  const handleChange = (e) => {
    setInputValue(e.target.value);
    if (errors.inputValue) {
      setErrors({});
    }
  };

  const handleDelete = () => {
    setSuccesDeleteTeam(false);
    if (inputValue === companyName) {
      setSuccesDeleteTeam(true);
      setTimeout(() => {
        onDelete();
        onClose();
      }, 1000);
    } else {
      setErrors({ inputValue: t("teamNameError") });
      setInputValue("");
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleDelete();
    }
  };

  const handleClickOutside = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return ReactDOM.createPortal(
    <div className={styles.popupOverlay} onClick={handleClickOutside}>
      {succesDeleteTeam && <SuccessPopup message={t("teamDeleted")} />}
      <div className={styles.popupContent}>
        <h2>{t("deleteTeam")}</h2>
        <p>{t("confirmDeleteTeam")} <strong>{companyName}</strong> {t("confirmDeleteTeam2")}</p>
        <input
          type="text"
          value={inputValue}
          onChange={handleChange}
          onKeyDown={handleKeyDown} 
          className={`${styles.input} ${errors.inputValue ? styles.inputError : ""}`}
          placeholder={t("teamName")}
        />
        {errors.inputValue && <span className={styles.error}>{errors.inputValue}</span>}
        <div className={styles.popupActions}>
          <button onClick={onClose} className={styles.buttonCancel}>{t("cancel")}</button>
          <button onClick={handleDelete} className={styles.buttonDelete}>{t("delete")}</button>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default EliminarEquipoPopup;
