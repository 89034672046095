import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchUserInfo = createAsyncThunk(
  "user/fetchInfo",
  async (_, thunkAPI) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL || "https://api.systemicsurvey.com";
      const response = await axios.get(`${apiUrl}/auth/me`, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });
      
      const userInfo = response.data;
      return userInfo;
    } catch (error) {
      throw new Error(error.response?.data?.message || "Error en el servidor");
    }
  },
);

export const refreshToken = createAsyncThunk("user/refreshToken", async () => {
  try {
    const apiUrl = process.env.REACT_APP_API_URL || "https://api.systemicsurvey.com";
    const response = await axios.post(`${apiUrl}/auth/refresh-token`, {}, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response?.data?.message || "Error en el servidor");
  }
});


export const updateProfileImage = createAction("user/updateProfileImage");
export const updateCompanyInfo = createAction("user/updateCompanyInfo");

const userSlice = createSlice({
  name: "user",
  initialState: {
    info: null,
    status: "idle",
    error: null,
    isChecked: false,
    companyInfo: {
      logo: "",
      companyName: "",
    },
    firstLoginUpdating: false,
  },
  reducers: {
    toggleRememberMe: (state, action) => {
      state.isChecked = action.payload;
    },
    logout: (state) => {
      state.info = null;
      state.status = "idle";
      state.error = null;
    },
    updateProfileImage: (state, action) => {
      state.info = { ...state.info, img_profile: action.payload };
    },
    updateCompanyInfo: (state, action) => {
      state.companyInfo = { ...state.companyInfo, ...action.payload };
    },
    setFirstLoginTrue: (state) => {
      if (state.info) {
        state.info.firstLogin = true;
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserInfo.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUserInfo.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.info = action.payload;
      })
      .addCase(fetchUserInfo.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(refreshToken.pending, (state) => {
        state.status = "loading";
      })
      .addCase(refreshToken.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.info = action.payload;
      })
      .addCase(refreshToken.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
  },
});

export const { toggleRememberMe, logout } = userSlice.actions;
export default userSlice.reducer;