import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationES from "../locales/es.json";
import translationEN from "../locales/en.json";


const resources = {
  es: {
    translation: translationES,
  },
  en: {
    translation: translationEN,
  }
};

const customLanguageDetector = {
  type: 'languageDetector',
  async: true,
  detect: (callback) => {
    let detectedLanguage = localStorage.getItem('i18nextLng');
    if (detectedLanguage) {
      // Solo toma los primeros dos caracteres del código de idioma
      // para obtener el código de idioma sin la región
      detectedLanguage = detectedLanguage.split('-')[0];
    } else {
      detectedLanguage = 'es'; // Un valor predeterminado si no hay nada en localStorage
    }

    callback(detectedLanguage);
  },
  init: () => {},
  cacheUserLanguage: (lng) => {
    localStorage.setItem('i18nextLng', lng);
  }
};

i18n
  .use(customLanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['customLanguageDetector', 'navigator'], // Añade el detector personalizado aquí
      caches: ['localStorage'],
    },
    whitelist: ['es', 'en', ],
    nonExplicitWhitelist: true,
    load: 'languageOnly',
  });

export default i18n;
