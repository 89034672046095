import React, { useState } from "react";
import useFetch from "../hooks/useFetch";
import mainStyle from "../styles/mainComponents.module.css";
import loginStyle from "../styles/login.module.css";
import Loader from "../components/Loader";
import { ErrorPopup, SuccessPopup } from "../components/Popup";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ButtonPrincipal from "../components/ButtonPrincipal";

function ResetPassword() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [localLoading, setLocalLoading] = useState(false);
  const { error, success, execute } = useFetch();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [passwordValidations, setPasswordValidations] = useState({
    length: false,
    upperCase: false,
    lowerCase: false,
    symbol: false,
    match: false,
  });

  const allValidationsPassed = Object.values(passwordValidations).every(Boolean);

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);

    setPasswordValidations({
      length: value.length >= 8 && value.length <= 15,
      upperCase: /[A-Z]/.test(value),
      lowerCase: /[a-z]/.test(value),
      symbol: /[!@#$%^&*(),.?":{}|<>]/.test(value),
      match: value === confirmPassword,
    });
  };

  const handleConfirmPasswordChange = (e) => {
    const value = e.target.value;
    setConfirmPassword(value);
    setPasswordValidations((prev) => ({ ...prev, match: password === value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!allValidationsPassed) return;

    setLocalLoading(true);
    try {
      const token = searchParams.get("token");
      const rol = searchParams.get("rol");
      const apiUrl = process.env.REACT_APP_API_URL || "https://api.systemicsurvey.com";
      
      await execute(`${apiUrl}/gestionCoach/resetPassword?token=${token}&rol=${rol}`, "POST", { password });
      
      navigate("/login");
    } catch (e) {
      console.error("Error al restablecer la contraseña:", e);
    } finally {
      setLocalLoading(false);
    }
  };

  return (
    <>
      <div className={loginStyle.general}>
        <div className={loginStyle.registerRight}>
          {localLoading && <Loader />}
          {error && <ErrorPopup message={t("errorResetPassword")} />}
          {success && <SuccessPopup message={t("successResetPassword")} />}
          <div className={`${loginStyle.registerDiv} ${mainStyle.container}`}>
            <img
              className={loginStyle.logoi8}
              src="https://www.8incompany.com/wp-content/uploads/2023/09/logo-8INCOMPANY-e1695725322150.png"
              alt="Logo"
            />
            <h1 className={loginStyle.formTittle}>{t("resetPassword")}</h1>
            <form onSubmit={handleSubmit} className={loginStyle.contenedorLogin}>
              <div className={loginStyle.formgroup}>
                <label htmlFor="password" className={loginStyle.formText}>{t("newPasswordPlaceholder")}</label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  className={loginStyle.inputRecovery}
                  value={password}
                  onChange={handlePasswordChange}
                  required
                />
              </div>
              <div className={loginStyle.formgroup}>
                <label htmlFor="confirmPassword" className={loginStyle.formText}>{t("confirmPasswordPlaceholder")}</label>
                <input
                  type="password"
                  name="confirmPassword"
                  id="confirmPassword"
                  className={loginStyle.inputRecovery}
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  required
                />
              </div>

              <ul className={loginStyle.passwordValidationList}>
                <li style={{ color: passwordValidations.length ? "green" : "red" }}>
                  {t("passwordValidation.charLength")}
                </li>
                <li style={{ color: passwordValidations.upperCase ? "green" : "red" }}>
                  {t("passwordValidation.upperCase")}
                </li>
                <li style={{ color: passwordValidations.lowerCase ? "green" : "red" }}>
                  {t("passwordValidation.lowerCase")}
                </li>
                <li style={{ color: passwordValidations.symbol ? "green" : "red" }}>
                  {t("passwordValidation.symbol")}
                </li>
                <li style={{ color: passwordValidations.match ? "green" : "red" }}>
                  {t("passwordValidation.match")}
                </li>
              </ul>

              <ButtonPrincipal
                type="submit"
                className={loginStyle.btnSubmit}
                disabled={!allValidationsPassed}
              >
                {t("submitNewPassword")}
              </ButtonPrincipal>
            </form>
          </div>
          <div className={mainStyle.logofeel}>
            <p>Powered by</p>
            <img className={mainStyle.logoLogin} src="/img/logo_largo.png" alt="Logo" />
          </div>
        </div>
        <div className={`${loginStyle.registerLeft} ${mainStyle.container}`}>
          <img src="/img/preview.png" width="500px" alt="" />
        </div>
      </div>
    </>
  );
}

export default ResetPassword;
