import React from "react";
import mainStyle from "../styles/mainComponents.module.css";

function InputAuth({ type, value, onChange, onFocus, placeholder, className }) {
  return (
    <input
      className={`${mainStyle.inputAuth} ${className}`}
      type={type}
      value={value}
      onChange={onChange}
      onFocus={onFocus}
      placeholder={placeholder}
    />
  );
}

export default InputAuth;
