// CompanySelector
import React, { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import styles from "../../styles/portals.module.css";
import { useTranslation } from "react-i18next";

function CompanySelector({ setSelectedCompany, setSelectedCompanyId, companies, rol, empresaUser }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const { t } = useTranslation();

  //Si el rol es ressponsable o ambos, seleccionamos la empresa del usuario buscando su id en el companies
  if (rol === "responsable" || rol === "ambos") {
    setSelectedCompanyId(empresaUser);
    setSelectedCompany('Mi empresa');
  }
    
  useEffect(() => {
    setIsVisible(true);
  }, []);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredCompanies = companies.filter((company) =>
    company.nombre.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleCompanySelection = (company) => {
    setSelectedCompany(company.nombre);
    setSelectedCompanyId(company.id_empresa);
  };

  return createPortal(
    <div className={`${styles.overlay} ${isVisible ? styles.show : styles.hide}`} >
      <div className={styles.content} onClick={(e) => e.stopPropagation()}>
        <h2 className={styles.title}>{t("selectCompany")}</h2>
        <div className={styles.searchContainer}>
          <input
            type="text"
            placeholder={t("searchCompany")}
            className={styles.input}
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>
        <ul className={styles.list}>
          {filteredCompanies.map((company) => (
            <li
            key={company.id_empresa}
            onClick={() => handleCompanySelection(company)}
            className={styles.listItem}
          >
              {company.nombre}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 -960 960 960"
                width="24px"
                fill="#5f6368"
              >
                <path d="m321-80-71-71 329-329-329-329 71-71 400 400L321-80Z" />
              </svg>
            </li>
          ))}
        </ul>
      </div>
    </div>,
    document.body
  );
}

export default CompanySelector;
