import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useNavigate } from "react-router-dom";
import Login from "../pages/Login";
import Home from "../pages/Home";
import Empresas from "../pages/Empresas";
import Equipo from "../pages/Equipo";
import Encuesta from "../pages/Encuesta";
import PasswordRecovery from "../pages/PasswordRecovery.jsx";
import ResetPassword from "../pages/ResetPassword.jsx";

const RedirectTo = ({ shouldRedirect }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (shouldRedirect) {
      navigate("/home");
    } else {
      navigate("/login");
    }
  }, [navigate, shouldRedirect]);

  return null;
};

function RoutesComponent({ shouldRedirect }) {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<RedirectTo shouldRedirect={shouldRedirect} />}/>
        <Route path="/login" element={<Login />} />
        <Route path="/home" element={<Home />} />
        <Route path="/empresas" element={<Empresas />} />
        <Route path="/equipo/:id" element={<Equipo />} />
        <Route path="/encuesta" element={<Encuesta />} /> 
        <Route path="/recover-password" element={<PasswordRecovery />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="*" element={<Login />} />
      </Routes>
    </Router>
  );
}

export default RoutesComponent;
