/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import styles from "../../styles/crearEmpresaPopup.module.css";
import { useTranslation } from "react-i18next";

const CrearEquipoPopup = ({ onClose, onCreate, companyId }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    id_empresa: companyId,
    nombre: "",
  });
  const [errors, setErrors] = useState({});
  
  const validate = (name, value) => {
    let newErrors = { ...errors };
  
    const textRegex = /^[a-zA-Z0-9\sñÑáéíóúÁÉÍÓÚüÜ+-.ç]+$/;
  
    if (!value || !textRegex.test(value)) {
      // Utiliza t() para mostrar el mensaje de error traducido
      newErrors[name] = t("invalidTeamName");
    } else {
      delete newErrors[name];
    }
  
    setErrors(newErrors);
  };
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    validate(name, value);
  };

  const handleCreate = () => {
    const allValid = Object.keys(formData).every((key) => {
      validate(key, formData[key]);
      return !errors[key];
    });

    if (allValid) {
      onCreate(formData);
      onClose();
    }
  };

  const handleClickOutside = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleCreate();
    }
  };

  // Añadir event listener para la tecla "Enter"
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [formData]); // Dependencia para asegurarse de que el estado más reciente de formData sea accesible

  return ReactDOM.createPortal(
    <div className={styles.popupOverlay} onClick={handleClickOutside}>
      <div className={styles.popupContent}>
        <h2>{t("createTeam")}</h2>
        <label className={styles.label}>
          {t("teamName")}:
          <input
            type="text"
            name="nombre"
            value={formData.nombre}
            onChange={handleChange}
            className={`${styles.input} ${errors.nombre ? styles.inputError : ""}`}
          />
          {errors.nombre && <span className={styles.error}>{errors.nombre}</span>}
        </label>
        <div className={styles.popupActions}>
          <button onClick={onClose} className={styles.buttonCancel}>{t("cancel")}</button>
          <button onClick={handleCreate} className={styles.buttonCreate}>{t("create")}</button>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default CrearEquipoPopup;
