import React from "react";
import { Autocomplete, TextField, Chip, Box } from "@mui/material";
import { useTranslation } from "react-i18next";

function TeamSelector({ teams, selectedTeams, handleTeamChange }) {
  const { t } = useTranslation();
  
  return (
    <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
      <Autocomplete
        multiple
        id="teams-select"
        options={teams}
        getOptionLabel={(option) => option.nombre}
        value={selectedTeams}
        onChange={handleTeamChange}
        renderTags={(value, getTagProps) => (
          <Box
            sx={{
              display: "flex",
              flexWrap: "nowrap",
              overflowX: "auto", // Permite desplazamiento horizontal si es necesario
              maxWidth: "100%", // Ajusta el ancho máximo
            }}
          >
            {value.slice(0, 2).map((option, index) => {
              const { key, ...tagProps } = getTagProps({ index }); // Extrae key y el resto de props
              return (
                <Chip
                  key={key}
                  label={option.nombre}
                  style={{
                    marginRight: 4,
                    backgroundColor: option.color, // Aplica el color específico del equipo
                    color: "black", // Ajusta el color del texto para contraste
                  }}
                  {...tagProps} // Pasa el resto de las props
                />
              );
            })}
            {value.length > 2 && (
              <Chip
                key="more-chips" // Asegúrate de que esta key sea única
                label={`+${value.length - 2} ${t("moreTeams")}`} // Muestra el número de equipos adicionales
                style={{
                  marginRight: 4,
                  backgroundColor: "#ccc", // Color para el indicador de más equipos
                  color: "black",
                }}
              />
            )}
          </Box>
        )}
        renderOption={(props, option, { selected }) => (
          <li
            {...props}
            style={{
              backgroundColor: selected ? option.color : "white",
              color: selected ? "black" : "inherit",
            }}
          >
            {option.nombre}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            placeholder={t("teamPlaceholder")}
          />
        )}
        style={{ width: "100%" }} // Ajusta el ancho según sea necesario
      />
    </div>
  );
}

export default TeamSelector;