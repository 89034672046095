import React from "react";
import { Bar, Radar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  RadialLinearScale,
  BarElement,
  RadarController,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels'; // Importar el plugin
import styles from "../../styles/dashboard.module.css";
import mainStyle from "../../styles/mainComponents.module.css";

// Registrar los componentes de Chart.js, incluyendo RadialLinearScale y ChartDataLabels
ChartJS.register(
  CategoryScale,
  LinearScale,
  RadialLinearScale,
  BarElement,
  RadarController,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels // Registrar el plugin de etiquetas de datos
);

function DashboardCharts({ dashboardData, isRadarChart }) {
  const { t } = useTranslation();

  if (!dashboardData) {
    return (
      <div className={styles.divPibito}>
        <img className={styles.pibito} src="/img/Pibito.png" alt="" />
        <p className={mainStyle.noData}>{t("noData")}</p>
      </div>
    );
  }

  const labels = ["Goals", "Roles", "Processes", "Interactions"];
  const datasets = [];

  // Preparar datos
  let personalData = [0, 0, 0, 0];
  if (dashboardData.mediaPersonal) {
    const { Goals, Roles, Processes, Interactions } = dashboardData.mediaPersonal;
    personalData = [Goals, Roles, Processes, Interactions];
  }

  datasets.push({
    label: t("personalAverage"),
    data: personalData,
    backgroundColor: isRadarChart ? "rgba(76, 135, 178, 0.5)" : "#4C87B2",
    borderColor: "#4C87B2",
    pointBackgroundColor: "#4C87B2",
    barThickness: 30,
    borderRadius: 5,
  });

  const { mediaEquipo, mediaEquipoEmpresa, mediaEquipoGlobal } = dashboardData;

  datasets.push(
    {
      label: t("teamAverage"),
      data: [
        mediaEquipo.Goals,
        mediaEquipo.Roles,
        mediaEquipo.Processes,
        mediaEquipo.Interactions,
      ],
      backgroundColor: isRadarChart ? "rgba(72, 186, 165, 0.5)" : "#48BAA5",
      borderColor: "#48BAA5",
      pointBackgroundColor: "#48BAA5",
      barThickness: 30,
      borderRadius: 5,
    },
    {
      label: t("companyAverage"),
      data: [
        mediaEquipoEmpresa.Goals,
        mediaEquipoEmpresa.Roles,
        mediaEquipoEmpresa.Processes,
        mediaEquipoEmpresa.Interactions,
      ],
      backgroundColor: isRadarChart ? "rgba(89, 89, 89, 0.5)" : "#595959",
      borderColor: "#595959",
      pointBackgroundColor: "#595959",
      barThickness: 30,
      borderRadius: 5,
    },
    {
      label: t("globalAverage"),
      data: [
        mediaEquipoGlobal.Goals,
        mediaEquipoGlobal.Roles,
        mediaEquipoGlobal.Processes,
        mediaEquipoGlobal.Interactions,
      ],
      backgroundColor: isRadarChart ? "rgba(227, 20, 127, 0.5)" : "#e3147f",
      borderColor: "#e3147f",
      pointBackgroundColor: "#e3147f",
      barThickness: 30,
      borderRadius: 5,
    }
  );

  const barOptions = {
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        max: 4,
        ticks: {
          stepSize: 1,
          font: { size: 16, family: "Inter, sans-serif" },
          color: "#e3147f",
        },
        grid: { color: "#e3147f", lineWidth: 0.25, drawBorder: false },
      },
      x: {
        ticks: { font: { size: 12, family: "Inter, sans-serif" }, color: "#e3147f" },
        grid: { drawBorder: false, lineWidth: 0.25 },
      },
    },
    plugins: {
      legend: {
        position: "right",
        labels: { font: { size: 16, family: "Inter, sans-serif" }, color: "#595959" },
      },
      datalabels: {
        color: '#595959',
        font: {
          weight: 'bold',
          size: 12,
        },
        anchor: 'end', // Posiciona las etiquetas al final de cada barra
        align: 'top',  // Alinea las etiquetas en la parte superior de cada barra
      },
    },
  };

  const radarOptions = {
    maintainAspectRatio: false,
    scales: {
      r: {
        beginAtZero: true,
        max: 4,
        ticks: { stepSize: 1, font: { size: 14, family: "Inter, sans-serif" } },
      },
    },
    plugins: {
      legend: {
        position: "right", // Coloca la leyenda a la derecha
        labels: { font: { size: 16, family: "Inter, sans-serif" } },
      },
      datalabels: {
        display: false, // Desactiva las etiquetas de datos para el gráfico de araña
      },
    },
  };

  return (
    <div className={styles.divInteriorGrafico}>
      {isRadarChart ? (
        <Radar data={{ labels, datasets }} options={radarOptions} />
      ) : (
        <Bar data={{ labels, datasets }} options={barOptions} />
      )}
    </div>
  );
}

export default DashboardCharts;
