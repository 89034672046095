import React, { useState } from "react";
import ReactDOM from "react-dom";
import styles from "../../styles/crearEmpresaPopup.module.css";
import { useTranslation } from "react-i18next";

const AñadirUsuarioPopup = ({ onClose, onCreate }) => {
  const { t } = useTranslation();

  // Para nombre y apellido (solo letras, espacios, guiones y apóstrofes permitidos)
  const regexNombreApellido = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s'-]+$/;
  // Para email (formato general de correo electrónico)
  const regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const [formData, setFormData] = useState({
    nombre: "",
    apellido: "",
    email: "",
  });

  const [errors, setErrors] = useState({});

  const validate = (name, value) => {
    let newErrors = { ...errors };

    switch (name) {
      case "nombre":
        if (!value || !regexNombreApellido.test(value)) {
          newErrors[name] = t("nameInvalid");
        } else {
          delete newErrors[name];
        }
        break;
      case "apellido":
        if (!value || !regexNombreApellido.test(value)) {
          newErrors[name] = t("surnameInvalid");
        } else {
          delete newErrors[name];
        }
        break;
      case "email":
        if (!value || !regexEmail.test(value)) {
          newErrors[name] = t("emailInvalid");
        } else {
          delete newErrors[name];
        }
        break;
      default:
        break;
    }

    setErrors(newErrors);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    validate(name, value);
  };

  const handleCreate = () => {
    // Verificamos que no haya errores antes de crear
    const allValid = !errors.nombre && !errors.apellido && !errors.email;

    if (allValid) {
      onCreate(formData);
      onClose();
    }
  };

  const handleClickOutside = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Evita que el formulario se envíe de manera predeterminada
      handleCreate();
    }
  };

  return ReactDOM.createPortal(
    <div className={styles.popupOverlay} onClick={handleClickOutside}>
      <div className={styles.popupContent}>
        <h2>{t("añadirUsuario")}</h2>
        <label className={styles.label}>
          {t("name")}:
          <input
            type="text"
            name="nombre"
            value={formData.nombre}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            className={`${styles.input} ${errors.nombre ? styles.inputError : ""}`}
          />
          {errors.nombre && <span className={styles.error}>{errors.nombre}</span>}
        </label>
        <label className={styles.label}>
          {t("surname")}:
          <input
            type="text"
            name="apellido"
            value={formData.apellido}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            className={`${styles.input} ${errors.apellido ? styles.inputError : ""}`}
          />
          {errors.apellido && <span className={styles.error}>{errors.apellido}</span>}
        </label>
        <label className={styles.label}>
          {t("email")}:
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            className={`${styles.input} ${errors.email ? styles.inputError : ""}`}
          />
          {errors.email && <span className={styles.error}>{errors.email}</span>}
        </label>
        <div className={styles.popupActions}>
          <button onClick={onClose} className={styles.buttonCancel}>
            {t("cancel")}
          </button>
          <button onClick={handleCreate} className={styles.buttonCreate}>
            {t("create")}
          </button>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default AñadirUsuarioPopup;
