import React, { useState } from "react";
import ReactDOM from "react-dom";
import styles from "../../styles/crearEmpresaPopup.module.css";
import { useTranslation } from "react-i18next";

const ImportarEmpleadosPopup = ({ onClose, onImport }) => {
  const [file, setFile] = useState(null);
  const { t } = useTranslation();

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleImport = () => {
    if (file) {
      onImport(file);
      onClose();
    }
  };

  const handleClickOutside = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return ReactDOM.createPortal(
    <div className={styles.popupOverlay} onClick={handleClickOutside}>
      <div className={styles.popupContent}>
        <h2>{t("importUsers")}</h2>
        <p>{t("importInstructions")}</p> {/* Instrucciones para el usuario */}
        <a href='/files/template.xlsx' download className={styles.downloadLink}>
          {t("downloadTemplate")} {/* Texto para el enlace de descarga */}
        </a>
        <input type="file" onChange={handleFileChange} className={styles.fileInput} accept=".xlsx, .xls" />
        <div className={styles.popupActions}>
          <button onClick={onClose} className={styles.buttonCancel}>{t("cancel")}</button>
          <button onClick={handleImport} className={styles.buttonImport}>{t("import")}</button>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default ImportarEmpleadosPopup;
