import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import styles from "../../styles/crearEmpresaPopup.module.css";
import { useTranslation } from "react-i18next";

const EditarUsuarioPopup = ({ onClose, onUpdate, user }) => {
  const { t } = useTranslation();

  const regexNombreApellido = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s'-]+$/;
  const regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const [formData, setFormData] = useState({
    id_empleado: user.id_empleado,
    nombre: user.nombre || "",
    apellido: user.apellido || "",
    email: user.email || "",
    rol: user.rol || "empleado",
  });

  const [errors, setErrors] = useState({});
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);

  const validate = (name, value) => {
    let newErrors = { ...errors };

    switch (name) {
      case "nombre":
        if (!value || !regexNombreApellido.test(value)) {
          newErrors[name] = t("nameInvalid");
        } else {
          delete newErrors[name];
        }
        break;
      case "apellido":
        if (!value || !regexNombreApellido.test(value)) {
          newErrors[name] = t("surnameInvalid");
        } else {
          delete newErrors[name];
        }
        break;
      case "email":
        if (!value || !regexEmail.test(value)) {
          newErrors[name] = t("emailInvalid");
        } else {
          delete newErrors[name];
        }
        break;
      default:
        break;
    }

    setErrors(newErrors);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    validate(name, value);
  };

  const handleRoleChange = (newRole) => {
    setFormData((prevData) => ({
      ...prevData,
      rol: newRole,
    }));

    if (newRole === "responsable" || newRole === "ambos") {
      setShowConfirmationPopup(true);
    }
  };

  const handleUpdate = () => {
    const allValid = !errors.nombre && !errors.apellido && !errors.email;

    if (allValid) {
      onUpdate(formData);
      onClose();
    }
  };

  const handleClickOutside = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleConfirm = () => {
    setShowConfirmationPopup(false);
  };

  const handleCancelConfirm = () => {
    setFormData((prevData) => ({
      ...prevData,
      rol: "empleado",
    }));
    setShowConfirmationPopup(false);
  };

  useEffect(() => {
    console.log(user);
  }, [user]);

  return ReactDOM.createPortal(
    <>
      <div className={styles.popupOverlay} onClick={handleClickOutside}>
        <div className={styles.popupContent}>
          <h2>{t("editUser")}</h2>
          <label className={styles.label}>
            {t("name")}:
            <input
              type="text"
              name="nombre"
              value={formData.nombre}
              onChange={handleChange}
              className={`${styles.input} ${errors.nombre ? styles.inputError : ""}`}
            />
            {errors.nombre && <span className={styles.error}>{errors.nombre}</span>}
          </label>
          <label className={styles.label}>
            {t("surname")}:
            <input
              type="text"
              name="apellido"
              value={formData.apellido}
              onChange={handleChange}
              className={`${styles.input} ${errors.apellido ? styles.inputError : ""}`}
            />
            {errors.apellido && <span className={styles.error}>{errors.apellido}</span>}
          </label>
          <label className={styles.label}>
            {t("email")}:
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className={`${styles.input} ${errors.email ? styles.inputError : ""}`}
            />
            {errors.email && <span className={styles.error}>{errors.email}</span>}
          </label>

          {/* Mostrar la sección de equipos solo si pertenece a más de un equipo */}
          {user.equipos.length > 1 && (
            <div className={styles.equiposContainer}>
              <h3>{user.nombre} {t("belongsToTeams")}</h3>
              <table className={styles.equiposTable}>
                <thead>
                  <tr>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {user.equipos.map((equipo) => (
                    <tr key={equipo.id_equipo} className={styles.equiposRow}>
                      <td className={styles.svgRes}>
                        {/* Mostrar el ícono de responsable solo si el empleado es responsable en ese equipo */}
                        {equipo.responsable ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="16px"
                            viewBox="0 0 24 24"
                            width="16px"
                            className={styles.responsibleIcon}
                          >
                            <path d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5s-3 1.34-3 3 1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5C14 14.17 9.33 13 8 13zm8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 2.03 1.97 3.45V19h4v-2.5c0-2.33-4.67-3.5-7-3.5z"/>
                          </svg>
                        ) : (
                          <span></span> // Texto si no es responsable
                        )}
                      </td>
                      <td>{equipo.nombre}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {/* Selector de Rol */}
          <div className={styles.rolSelector}>
            <span
              className={`${styles.rolOption} ${formData.rol === "responsable" ? styles.selected : ""}`}
              onClick={() => handleRoleChange("responsable")}
            >
              {t("manager")}
            </span>
            <span
              className={`${styles.rolOption} ${formData.rol === "empleado" ? styles.selected : ""}`}
              onClick={() => handleRoleChange("empleado")}
            >
              {t("employee")}
            </span>
            <span
              className={`${styles.rolOption} ${formData.rol === "ambos" ? styles.selected : ""}`}
              onClick={() => handleRoleChange("ambos")}
            >
              {t("both")}
            </span>
          </div>

          <div className={styles.popupActions}>
            <button onClick={onClose} className={styles.buttonCancel}>
              {t("cancel")}
            </button>
            <button onClick={handleUpdate} className={styles.buttonUpdate}>
              {t("update")}
            </button>
          </div>
        </div>
      </div>

      {showConfirmationPopup && (
        <div className={styles.popupOverlay} onClick={handleClickOutside}>
          <div className={styles.popupContent}>
            <h3 className={styles.h3confirm}>{t("confirmRoleChangeTitle")}</h3>
            <p className={styles.pconfirm}>{t("confirmRoleChangeMessage")}</p>
            <div className={styles.popupActions}>
              <button onClick={handleCancelConfirm} className={styles.buttonCancel}>
                {t("cancel")}
              </button>
              <button onClick={handleConfirm} className={styles.buttonConfirm}>
                {t("confirm")}
              </button>
            </div>
          </div>
        </div>
      )}
    </>,
    document.body
  );
};

export default EditarUsuarioPopup;