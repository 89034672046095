import React from "react";
import ReactDOM from "react-dom";
import styles from "../../styles/crearEmpresaPopup.module.css";
import { useTranslation } from "react-i18next";

const EliminarUsuarioPopup = ({ onClose, onDelete, userName }) => {
  const { t } = useTranslation();
  const handleDelete = () => {
    onDelete();
    onClose();
  };

  const handleClickOutside = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return ReactDOM.createPortal(
    <div className={styles.popupOverlay} onClick={handleClickOutside}>
      <div className={styles.popupContent}>
        <h2>{t("deleteUser")}</h2>
        <p>{t("deleteUserConfirmation")} <strong>{userName}</strong>?</p>
        <div className={styles.popupActions}>
          <button onClick={onClose} className={styles.buttonCancel}>{t("cancel")}</button>
          <button onClick={handleDelete} className={styles.buttonDelete}>{t("delete")}</button>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default EliminarUsuarioPopup;
