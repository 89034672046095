import React, { useState } from "react";
import ReactDOM from "react-dom";
import styles from "../../styles/crearEmpresaPopup.module.css";
import { useTranslation } from "react-i18next";

const EliminarEmpresaPopup = ({ onClose, onDelete, companyName }) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState("");
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setInputValue(e.target.value);
    if (errors.inputValue) {
      setErrors({});
    }
  };

  const handleDelete = () => {
    if (inputValue === companyName) {
      onDelete();
      onClose();
    } else {
      setErrors({ inputValue: t("companyNameError") });
      setInputValue("");
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleDelete();
    }
  };

  const handleClickOutside = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return ReactDOM.createPortal(
    <div className={styles.popupOverlay} onClick={handleClickOutside}>
      <div className={styles.popupContent}>
        <h2>{t("deleteCompany")}</h2>
        <p>{t("confirmDeleteCompany")} <strong>{companyName}</strong> {t("confirmDeleteCompany2")}</p>
        <input
          type="text"
          value={inputValue}
          onChange={handleChange}
          onKeyDown={handleKeyDown}  
          className={`${styles.input} ${errors.inputValue ? styles.inputError : ""}`}
          placeholder={t("companyName")}
        />
        {errors.inputValue && <span className={styles.error}>{errors.inputValue}</span>}
        <div className={styles.popupActions}>
          <button onClick={onClose} className={styles.buttonCancel}>{t('cancel')}</button>
          <button onClick={handleDelete} className={styles.buttonDelete}>{t('delete')}</button>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default EliminarEmpresaPopup;
