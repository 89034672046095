import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import styles from "../styles/popup.module.css";

const PopupComponent = ({ message, type }) => {
  const [opacity, setOpacity] = useState(1);
  const [isVisible, setIsVisible] = useState(true);
  const timerRef = useRef(null);

  useEffect(() => {
    const timerDuration = 5000;
    const fadeDuration = 500;

    setTimeout(() => {
      setOpacity(0);
      setTimeout(() => {
        setIsVisible(false);
      }, fadeDuration);
    }, timerDuration);

    const timerElement = timerRef.current;
    if (timerElement) {
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          timerElement.style.transition = `width ${timerDuration}ms linear`;
          timerElement.style.width = "100%";
        });
      });
    }

    return () => {
      clearTimeout(timerDuration);
      clearTimeout(fadeDuration);
    };
  }, []);

  if (!isVisible) {
    return null;
  }
  const iconSvg = {
    error: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        viewBox="0 -960 960 960"
        width="24"
      >
        <path d="M480-280q17 0 28.5-11.5T520-320q0-17-11.5-28.5T480-360q-17 0-28.5 11.5T440-320q0 17 11.5 28.5T480-280Zm-40-160h80v-240h-80v240Zm40 360q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
      </svg>
    ),
    info: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        viewBox="0 -960 960 960"
        width="24"
      >
        <path d="M440-280h80v-240h-80v240Zm40-320q17 0 28.5-11.5T520-640q0-17-11.5-28.5T480-680q-17 0-28.5 11.5T440-640q0 17 11.5 28.5T480-600Zm0 520q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z" />
      </svg>
    ),
    success: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        viewBox="0 -960 960 960"
        width="24"
      >
        <path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z" />
      </svg>
    ),
  };

  return ReactDOM.createPortal(
    <div
      className={`${styles.popup} ${styles[`popup--${type}`]}`}
      style={{ opacity }}
    >
      <div className={styles.popup__icon}>{iconSvg[type]}</div>
      <div className={styles.popup__title}>{message}</div>
      <div ref={timerRef} className={styles.popup__timer}></div>
    </div>,
    document.body,
  );
};

export const ErrorPopup = ({ message }) => (
  <PopupComponent message={message} type="error" />
);
export const InfoPopup = ({ message }) => (
  <PopupComponent message={message} type="info" />
);
export const SuccessPopup = ({ message }) => (
  <PopupComponent message={message} type="success" />
);
