import React from 'react';
import mainStyle from '../../styles/mainComponents.module.css';
import { useTranslation } from "react-i18next";

function Bottom5({ bottom5 }) {
  const { t } = useTranslation();

  const getInitial = (modelo) => {
    switch (modelo) {
      case 'Goals':
        return 'G';
      case 'Roles':
        return 'R';
      case 'Processes':
        return 'P';
      case 'Interactions':
        return 'I';
      default:
        return 'N/A';
    }
  };

  return (
    <div className={mainStyle.interiorBottom5}>
      <h2>{t("top3Bottom")}</h2>
      <hr />
      <div className={mainStyle.divTopsBottom}>
        {bottom5 && bottom5.length > 0 ? (
          bottom5.map((item, index) => (
            <ul>
            <li key={index} className={mainStyle.listItem}>
              <div className={mainStyle.icon}>{getInitial(item.modelo)}</div>
              <div className={mainStyle.text}>{item.pregunta}</div>
              <div className={mainStyle.score}>{item.media_puntuacion}</div>
            </li>
              </ul>
          ))
        ) : (
          <div className={mainStyle.noDataBottomComponent}>
            <p className={mainStyle.noData}>{t("noData")}</p>
          </div>
        )}
            </div>
    </div>
  );
}

export default Bottom5;
