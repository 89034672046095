/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useFetch from "../hooks/useFetch";
import Confetti from 'react-confetti';
import styles from "../styles/encuesta.module.css";
import { useTranslation } from "react-i18next";
import Select from "react-select";

function Encuesta() {
    const [idEmpleado, setIdEmpleado] = useState(null);
    const [idEncuesta, setIdEncuesta] = useState(null);
    const [preguntas, setPreguntas] = useState([]);
    const [totalPreguntas, setTotalPreguntas] = useState(0);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [selectedOption, setSelectedOption] = useState(null);
    const [textResponse, setTextResponse] = useState('');
    const [encuestaCompletada, setEncuestaCompletada] = useState(false);
    const [showAviso, setShowAviso] = useState(true); 
    const [hideAviso, setHideAviso] = useState(false); 
    const location = useLocation();
    const navigate = useNavigate();
    const { data, execute } = useFetch();
    const { execute: execute2 } = useFetch();
    const executeRef = useRef(execute);
    const executeRef2 = useRef(execute2);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { t, i18n } = useTranslation();
    const [estadoEncuesta, setEstadoEncuesta] = useState(null);

    const opcionesIdioma = [
        {
          value: "es",
          label: (
            <div>
              <img
                src="/img/banderas/es.png"
                alt="ES"
                style={{ width: "20px", height: "15px", marginRight: "10px" }}
              />{" "}
              Español
            </div>
          ),
        },
        {
          value: "en",
          label: (
            <div>
              <img
                src="/img/banderas/en.png"
                alt="EN"
                style={{ width: "20px", height: "15px", marginRight: "10px" }}
              />{" "}
              English
            </div>
          ),
        },
      ];
    
      const cambiarIdioma = (opcionSeleccionada) => {
        i18n.changeLanguage(opcionSeleccionada.value);
      };

    const fetchApiDataPost = async (url, executeRef, body) => {
        try {
            const apiUrl = process.env.REACT_APP_API_URL || "https://api.systemicsurvey.com";
            await executeRef.current(`${apiUrl}${url}`, "POST", body);
        } catch (error) {
            console.error("Error posting to API:", error);
        }
    };

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const token = queryParams.get('token');
        if (token) {
            try {
                const decodedToken = atob(token);
                const [id_encuesta, id_empleado] = decodedToken.split(':');
                if (!id_encuesta || !id_empleado || isNaN(id_encuesta) || isNaN(id_empleado)) {
                    throw new Error('Token inválido');
                }
                fetchApiDataPost("/gestionEncuestas/getPreguntas", executeRef, { id_encuesta, id_empleado, idioma: i18n.language });
                setIdEmpleado(id_empleado);
                setIdEncuesta(id_encuesta);
            } catch (error) {
                console.error('Error procesando token:', error);
            }
        }
    }, [location.search, navigate, i18n.language]);

    useEffect(() => {
        executeRef.current = execute;
    }, [execute]);

    useEffect(() => {
        if (data) {
            if (data.preguntasPorContestar && data.preguntasPorContestar.length > 0) {
                setPreguntas(data.preguntasPorContestar);
            } else {
                setEncuestaCompletada(true);
            }
            setEstadoEncuesta(data.estadoEncuesta);
            setTotalPreguntas(data.totalPreguntas);
        }
    }, [data]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (preguntas[currentQuestionIndex]?.tipo !== 'texto') {
                if (event.key >= '1' && event.key <= '4') {
                    setSelectedOption(parseInt(event.key) - 1);
                } else if (event.key === 'Enter') {
                    handleNextQuestion();
                }
            }
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [selectedOption, currentQuestionIndex, preguntas]);

    const handleNextQuestion = async () => {
        const currentQuestion = preguntas[currentQuestionIndex];
        
        // Evitar que se envíe una respuesta si ya hay una petición en curso
        if (isSubmitting) {
            return;
        }
        
        if ((currentQuestion.tipo === 'texto') || (currentQuestion.tipo !== 'texto' && selectedOption !== null)) {
            setIsSubmitting(true); // Bloquear más interacciones mientras se envía
            
            await fetchApiDataPost("/gestionEncuestas/postRespuesta", executeRef2, {
                id_encuesta: idEncuesta,
                id_pregunta: currentQuestion.id_pregunta,
                id_empleado: idEmpleado,
                puntuacion: currentQuestion.tipo !== 'texto' ? selectedOption + 1 : null,
                comentario: currentQuestion.tipo === 'texto' ? textResponse : null,
            });
    
            setSelectedOption(null);
            setTextResponse('');
            
            // Ocultar el aviso tras la primera respuesta
            if (showAviso) {
                handleHideAviso();
            }
            
            if (currentQuestionIndex < preguntas.length - 1) {
                setCurrentQuestionIndex(currentQuestionIndex + 1);
            } else {
                setEncuestaCompletada(true);
            }
    
            // Permitir nuevas interacciones una vez finalizada la petición
            setIsSubmitting(false);
        }
    };

    // Maneja la animación de salida del aviso
    const handleHideAviso = () => {
        setHideAviso(true);
        setTimeout(() => {
            setShowAviso(false);
        }, 500); // Duración de la animación de salida
    };

    const handlePreviousQuestion = () => {
        if (currentQuestionIndex > 0) {
            setCurrentQuestionIndex(currentQuestionIndex - 1);
            setSelectedOption(null);
            setTextResponse('');
        }
    };

    return (
        <div className={styles.allSurvey}>
            {encuestaCompletada && <Confetti recycle={false} numberOfPieces={500} />}
            {showAviso && estadoEncuesta !== 'finalizada' && (
                <div className={`${styles.aviso} ${hideAviso ? styles.hide : ''}`}>
                    <div className={styles.paddingAviso}>
                        <p>{t("reminderKeyboard")}</p>
                        <div className={styles.pulsadoresinfo}>
                            <span>{t("pulse1")}</span> <span>{t("pulse2")}</span> <span>{t("pulse3")}</span> <span>{t("pulse4")}</span> <span>{t("pulseEnter")}</span>
                        </div>
                    </div>
                </div>
            )}
    
            <div className={styles.sidebarSurvey}>
                <img className={styles.logoi8} src="/img/logo8.png" alt="Instituto Logo" />
                <div className={styles.logofeel}>
                    <p>Powered by</p>
                    <img className={styles.logoLogin} src="/img/logo_largo.png" alt="FeelinBack Logo" />
                </div>
            </div>
    
            <div className={styles.encuestaContainer}>
                <div className={styles.changeLanguage}>
                    <Select
                        options={opcionesIdioma}
                        placeholder="Cambiar idioma"
                        menuPlacement="top"
                        onChange={cambiarIdioma}
                        value={opcionesIdioma.find(
                            (opcion) => opcion.value === i18n.language,
                        )}
                    />
                </div>
    
                {/* Verificar si la encuesta está finalizada */}
                {estadoEncuesta === 'finalizada' ? (
                    <div className={styles.encuestaCard}>
                        <h2>{t("encuestaFinalizada")}</h2>
                        <p>{t("noPuedesResponder")}</p>
                    </div>
                ) : encuestaCompletada ? (
                    <div className={styles.encuestaCard}>
                        <h2>{t("thanksForAnswer")}</h2>
                        <p>{t("participationIsImportant")}</p>
                    </div>
                ) : preguntas.length > 0 ? (
                    <div className={styles.encuestaCard}>
                        <div className={styles.encuestaProgressText}>
                            {totalPreguntas - preguntas.length + currentQuestionIndex + 1}/{totalPreguntas}
                        </div>
                        <div className={styles.encuestaProgress}>
                            <div
                                className={styles.encuestaProgressBar}
                                style={{ width: `${(((totalPreguntas - preguntas.length + currentQuestionIndex + 1) / totalPreguntas) * 100)}%` }}
                            ></div>
                        </div>
                        <div className={styles.pregunta}>
                            {preguntas[currentQuestionIndex].pregunta}
                        </div>
                        {preguntas[currentQuestionIndex].tipo === 'texto' ? (
                            <textarea
                                className={styles.textArea}
                                value={textResponse}
                                onChange={(e) => setTextResponse(e.target.value)}
                                placeholder={t("writeYourAnswer")}
                            ></textarea>
                        ) : (
                            <div className={styles.opciones}>
                                {[...Array(4)].map((_, index) => (
                                    <div
                                        key={index}
                                        className={`${styles.opcion} ${selectedOption === index ? styles.selected : ''}`}
                                        onClick={() => setSelectedOption(index)}
                                    >
                                        <div className={styles.circle}></div>
                                    </div>
                                ))}
                            </div>
                        )}
                        {preguntas[currentQuestionIndex].tipo !== 'texto' && (
                            <div className={styles.opcionLabels}>
                                <span>{t("stronglyDisagree")}</span>
                                <span>{t("veryAgree")}</span>
                            </div>
                        )}
                        <div className={styles.botonContainer}>
                            <button
                                className={`${styles.boton} ${styles.botonAnterior}`}
                                onClick={handlePreviousQuestion}
                                style={{ visibility: currentQuestionIndex === 0 ? 'hidden' : 'visible' }}
                            >
                                {t("previous")}
                            </button>
                            <button
                                className={`${styles.boton} ${((selectedOption === null && preguntas[currentQuestionIndex].tipo !== 'texto')) ? styles.disabled : ''} ${isSubmitting ? styles.submitting : ''}`}
                                onClick={handleNextQuestion}
                                disabled={((selectedOption === null && preguntas[currentQuestionIndex].tipo !== 'texto') || isSubmitting)}
                            >
                                {currentQuestionIndex === preguntas.length - 1 ? t("finish") : t("next")}
                            </button>
                        </div>
                    </div>
                ) : (
                    <p>{t("chargingQuestions")}</p>
                )}
            </div>
    
            <div className={styles.cuchiliuau}>
                <img className={styles.logoi8Mobile} src="/img/logo8.png" alt="Instituto Logo" />
                <div className={styles.logofeelMobile}>
                    <p>Powered by</p>
                    <img className={styles.logoLoginMobile} src="/img/logo_largo.png" alt="FeelinBack Logo" />
                </div>
            </div>
        </div>
    );
    

}

export default Encuesta;
