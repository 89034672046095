// ButtonPrincipal.js
import React from "react";
import mainStyle from "../styles/mainComponents.module.css";

function ButtonPrincipal({ children, onClick, type = "button", disabled, className, id, style }) {
  return (
    <button
      className={`${mainStyle.btnPrincipal} ${className}`}
      onClick={onClick}
      type={type}
      style={style}
      disabled={disabled} // Asegúrate de que el botón recibe la propiedad disabled
      id={id}
    >
      {children}
    </button>
  );
}

export default ButtonPrincipal;
