/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import SideBar from "../components/SideBar";
import CompanySelector from "../components/Portals/SeleccionarEmpresas";
import mainStyle from "../styles/mainComponents.module.css";
import useFetch from "../hooks/useFetch";
import { CompanyContext } from "../features/CompanyContext";
import Top5 from "../components/homeComponents/Top5";
import Bottom5 from "../components/homeComponents/Bottom5";
import TeamSelector from "../components/homeComponents/TeamSelector";
import DashboardCharts from "../components/homeComponents/DashboardCharts";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

function Home() {
  const { selectedCompany, setSelectedCompany, selectedCompanyId, setSelectedCompanyId } = useContext(CompanyContext);
  const [companies, setCompanies] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [top5, setTop5] = useState([]);
  const [bottom5, setBottom5] = useState([]);
  const [teams, setTeams] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [dashboardData, setDashboardData] = useState(null);
  const { data: data1, execute: execute1 } = useFetch();
  const { data: data2, execute: execute2 } = useFetch();
  const { i18n } = useTranslation();
  const userInfo = useSelector((state) => state.user.info);
  const [isRadarChart, setIsRadarChart] = useState(false); // Nuevo estado para alternar gráficos

  const fetchApiData = async (url) => {
    const apiUrl = process.env.REACT_APP_API_URL || "https://api.systemicsurvey.com";
    await execute1(`${apiUrl}${url}`, "GET");
  };

  const fetchApiData2 = async (url) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL || "https://api.systemicsurvey.com";
      await execute2(`${apiUrl}${url}`, "POST", { id_empresa: selectedCompanyId, id_equipo: selectedTeams.map((team) => team.id_equipo), idioma: i18n.language });
    } catch (e) {
      console.log("Error en la petición:", e);
    }
  };

  const generatePastelColor = () => {
    const hue = Math.floor(Math.random() * 360);
    return `hsl(${hue}, 70%, 85%)`;
  };

  useEffect(() => {
    fetchApiData("/gestionCoach/getEmpresas");
  }, []);

  useEffect(() => {
    if (data1 && data1.length > 0) {
      setCompanies(data1);
    } else {
      setCompanies([]);
    }
  }, [data1]);

  // Extraer equipos de la empresa seleccionada
  useEffect(() => {
    if (data1 && selectedCompanyId) {
      const selectedCompanyData = data1.find(company => company.id_empresa === parseInt(selectedCompanyId));
      if (selectedCompanyData && selectedCompanyData.equipos && selectedCompanyData.equipos.length > 0) {
        const equiposConColores = selectedCompanyData.equipos.map((equipo) => ({
          ...equipo,
          color: generatePastelColor(),
        }));
        setTeams(equiposConColores);
        const randomIndex = Math.floor(Math.random() * equiposConColores.length);
        setSelectedTeams([equiposConColores[randomIndex]]);
      } else {
        setTeams([]);
        setSelectedTeams([]);
      }
    } else {
      setTeams([]);
      setSelectedTeams([]);
    }
  }, [data1, selectedCompanyId]);

  // Obtener datos del dashboard cuando selectedTeams cambia
  useEffect(() => {
    if (selectedCompanyId && selectedTeams.length > 0) {
      fetchApiData2("/gestionDashboard/getDashboardData");
    } else {
      setDashboardData(null);
      setTop5([]);
      setBottom5([]);
    }
  }, [selectedCompanyId, selectedTeams, i18n.language]);

  // Manejar los datos del dashboard
  useEffect(() => {
    if (data2 && Object.keys(data2).length > 0) {
      setTop5(data2.top5 || []);
      setBottom5(data2.bottom5 || []);
      setDashboardData(data2);
    } else {
      setTop5([]);
      setBottom5([]);
      setDashboardData(null);
    }
  }, [data2]);

  useEffect(() => {
    setIsModalOpen(!selectedCompany);
  }, [selectedCompany]);

  const handleTeamChange = (event, selectedOptions) => {
    setSelectedTeams(selectedOptions || []);
  };

  return (
    <>
      <div className={mainStyle.main}>
        <SideBar selectedCompany={selectedCompany} />
        <div className={mainStyle.central}>
          <div className={mainStyle.verticalHome}>
            <div className={mainStyle.selectorDivEquipo}>
            {dashboardData && (
              <button
                className={mainStyle.btnChange} // Usaremos este estilo para el botón
                onClick={() => setIsRadarChart(!isRadarChart)}
              >
                {isRadarChart ? "Cambiar a gráfico de barras" : "Cambiar a gráfico de araña"}
              </button>
            )}
              <div className={mainStyle.switchContainer}>
              </div>
              <TeamSelector
                teams={teams}
                selectedTeams={selectedTeams}
                handleTeamChange={handleTeamChange}
              />
            </div>
            <div className={mainStyle.divGrafico}>
            <DashboardCharts dashboardData={dashboardData} isRadarChart={isRadarChart}/>
            </div>
            <div className={mainStyle.topPreguntasDiv}>
              <div className={mainStyle.exteriorTop5}>
                <Top5 top5={top5} />
              </div>
              <div className={mainStyle.exteriorBottom5}>
                <Bottom5 bottom5={bottom5} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <CompanySelector
          setSelectedCompany={setSelectedCompany}
          setSelectedCompanyId={setSelectedCompanyId}
          companies={companies}
          rol={userInfo?.rol}
          empresaUser={userInfo?.id_empresa}
        />
      )}
    </>
  );
}

export default Home;