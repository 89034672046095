import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import SideBar from "../components/SideBar";
import CompanySelector from "../components/Portals/SeleccionarEmpresas";
import mainStyle from "../styles/mainComponents.module.css";
import styles from "../styles/empresas.module.css";
import useFetch from "../hooks/useFetch";
import { CompanyContext } from "../features/CompanyContext";
import ButtonPrincipal from "../components/ButtonPrincipal";
import CrearEmpresaPopup from "../components/Portals/CrearEmpresaPopup";
import CrearEquipoPopup from "../components/Portals/CrearEquipoPopup";
import GestionEncuesta from "../components/Portals/GestionEncuesta";
import EliminarEmpresaPopup from "../components/Portals/EliminarEmpresaPopup";
import { ErrorPopup, InfoPopup, SuccessPopup } from "../components/Popup";
import { useTranslation } from "react-i18next";

function Empresas() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { selectedCompany, setSelectedCompany, selectedCompanyId, setSelectedCompanyId } = useContext(CompanyContext);
  const [openSurveyGroups, setOpenSurveyGroups] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [expandedCompanies, setExpandedCompanies] = useState({});
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isCreateTeamPopupOpen, setIsCreateTeamPopupOpen] = useState(false);
  const [companyIdForTeam, setCompanyIdForTeam] = useState(null);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [isGestionEncuestaOpen, setIsGestionEncuestaOpen] = useState(false);
  const [companyToDelete, setCompanyToDelete] = useState(null);
  const [selectedTeamIds, setSelectedTeamIds] = useState([]);
  const { data, execute } = useFetch();
  const { execute: execute2, error: error2 } = useFetch();
  const { execute: execute3, error: error3 } = useFetch();
  const { execute: execute4 } = useFetch();
  const executeRef = useRef(execute);
  const executeRef2 = useRef(execute2);
  const [success, setSuccess] = useState(false);
  const [successDelete, setSuccessDelete] = useState(false);
  const [successCreateTeam, setSuccessCreateTeam] = useState(false);
  const [infoSelectTeams, setInfoSelectTeams] = useState(false);
  const [succesSendNow, setSuccesSendNow] = useState(false);

  const getSelectedTeams = () => {
    const teams = [];
    companies.forEach(company => {
      if (company.equipos) {
        company.equipos.forEach(equipo => {
          if (selectedTeamIds.includes(equipo.id_equipo)) {
            teams.push(equipo);
          }
        });
      }
    });
    return teams;
  };
  
  
  const fetchApiData = (url, executeRef) => {
    const apiUrl = process.env.REACT_APP_API_URL || "https://api.systemicsurvey.com";
    executeRef.current(`${apiUrl}${url}`, "GET");
  };

  const fetchApiDataPost = async (url, executeRef, body) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL || "https://api.systemicsurvey.com";
      const response = await executeRef.current(`${apiUrl}${url}`, "POST", body);
      console.log("Response from API:", response);
      
    } catch (error) {
      console.error("Error posting to API:", error);
    }
  };

  const fetchApiDataDelete = async (url, executeRef, body) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL || "https://api.systemicsurvey.com";
      await executeRef.current(`${apiUrl}${url}`, "DELETE", body);
    } catch (error) {
      console.error("Error deleting from API:", error);
    }
  };

  useEffect(() => {
    executeRef.current = execute;
  }, [execute]);

  useEffect(() => {
    fetchApiData("/gestionCoach/getEmpresas", executeRef);
  }, []);

  useEffect(() => {
    if (data) {
      setCompanies(data);
    }
  }, [data]);

  useEffect(() => {
    if (companies.length === 0) {
      setIsModalOpen(false);
    } else if (selectedCompany == null) {
      setIsModalOpen(true);
    } else if (selectedCompany) {
      setIsModalOpen(false);
    }
  }, [companies.length, selectedCompany]);

const getEncuestaEstado = (encuestas) => {
  if (!encuestas || encuestas.length === 0) {
    return null;
  }

  const activa = encuestas.find(encuesta => encuesta && encuesta.estado === 'activa');
  const programada = encuestas.find(encuesta => encuesta && encuesta.estado === 'programada');

  if (activa) {
    return {
      estado: 'activa',
      id: activa.id_encuesta,
      fechas: `${activa.fecha_inicio} - ${activa.fecha_fin}`,
      recordatorio: activa.fecha_recordatorio,
      contenidoCorreo: activa.contenidoCorreo
    };
  } else if (programada) {
    return {
      estado: 'programada',
      id: programada.id_encuesta,
      fechas: `${programada.fecha_inicio} - ${programada.fecha_fin}`,
      recordatorio: programada.fecha_recordatorio,
      contenidoCorreo: programada.contenidoCorreo
    };
  }

  return null;
};

  const toggleExpandCompany = (companyId) => {
    setExpandedCompanies(prevState => ({
      ...prevState,
      [companyId]: !prevState[companyId],
    }));
  };

  const handleCreateEmpresa = (newCompany) => {
    setSuccess(false);
    fetchApiDataPost("/gestionCoach/crearEmpresa", executeRef2, newCompany);
    setCompanies([...companies, newCompany]);
    setSuccess(true);
    setTimeout(() => {
      fetchApiData("/gestionCoach/getEmpresas", executeRef);
    }, 1000);
  };

  const handleCreateEquipo = (newTeam) => {
    setSuccessCreateTeam(false);
    fetchApiDataPost("/gestionCoach/crearEquipo", executeRef2, newTeam);
    setSuccessCreateTeam(true);
    setExpandedCompanies(prevState => ({
      ...prevState,
      [newTeam.id_empresa]: true,
    }));
    setTimeout(() => {
      fetchApiData("/gestionCoach/getEmpresas", executeRef);
    }, 1000);
  };

  const handleDeleteEmpresa = () => {
    if (companyToDelete) {
      setSuccessDelete(false);
      fetchApiDataDelete(`/gestionCoach/deleteEmpresa`, executeRef2, {id_empresa: companyToDelete.id_empresa});
      setCompanies(companies.filter(company => company.id_empresa !== companyToDelete.id_empresa));
      setCompanyToDelete(null);
      setSuccessDelete(true);
      setTimeout(() => {
        fetchApiData("/gestionCoach/getEmpresas", executeRef);
      }, 1000);
    }
  };

  const openDeletePopup = (company) => {
    setCompanyToDelete(company);
    setIsDeletePopupOpen(true);
  };

  const openCreateTeamPopup = (companyId) => {
    setCompanyIdForTeam(companyId);
    setIsCreateTeamPopupOpen(true);
  };

  const handleEquipoClick = (equipoId) => {
    navigate(`/equipo/${equipoId}`);
  };

  const handleCheckboxChange = (equipoId, checked) => {
    if (checked) {
      setSelectedTeamIds((prev) => [...prev, equipoId]);
    } else {
      setSelectedTeamIds((prev) => prev.filter((id) => id !== equipoId));
    }
  };
  

  const groupTeamsBySurvey = () => {
    const selectedTeams = getSelectedTeams();
    const groups = selectedTeams.reduce((acc, team) => {
      const encuesta = getEncuestaEstado(team.encuestas);
      if (encuesta) {
        
        if (!acc[encuesta.id]) {
          acc[encuesta.id] = {
            encuesta: {
              ...encuesta,
              fecha_recordatorio: encuesta.recordatorio || 'Sin recordatorio', // Añadir la fecha_recordatorio
              contenidoCorreo: encuesta.contenidoCorreo || undefined // Añadir contenidoCorreo
            },
            equipos: [],
          };
        }
        acc[encuesta.id].equipos.push(team);
      } else {
        if (!acc['no-encuesta']) {
          acc['no-encuesta'] = {
            encuesta: {
              id: 'no-encuesta',
              fechas: 'Nueva encuesta',
              fecha_recordatorio: 'No disponible', // Puede ser un valor por defecto si no hay encuesta
              contenidoCorreo: undefined // Puede ser un valor por defecto si no hay encuesta
            },
            equipos: [],
          };
        }
        acc['no-encuesta'].equipos.push(team);
      }
      return acc;
    }, {});
    return groups;
  };
  
  const surveyGroups = groupTeamsBySurvey();
  

  const handleGestionarEncuesta = async () => {
    setInfoSelectTeams(false);
    await new Promise(resolve => setTimeout(resolve, 10));
  
    const selectedTeams = getSelectedTeams();
  
    if (selectedTeams.length === 0) {
      setInfoSelectTeams(true);
    } else {
      setOpenSurveyGroups(Object.keys(surveyGroups));
      setIsGestionEncuestaOpen(true);
    }
  };
  

const handleDeleteEncuesta = async (selectedSurveyId) => {
  const selectedTeams = getSelectedTeams();
  const url = "/gestionEncuestas/eliminarEncuesta";
  const body = {
      id_encuesta: selectedSurveyId,
      equipos: selectedTeams.map(e => e.id_equipo),
  };

  try {
    const apiUrl = process.env.REACT_APP_API_URL || "https://api.systemicsurvey.com";
    const response = await execute3(`${apiUrl}${url}`, "POST", body);
    if (response && response.status === 200) {
      setSuccess(true);
      setOpenSurveyGroups((prev) => prev.filter(id => id !== selectedSurveyId)); // Cierra la pestaña actual
      fetchApiData("/gestionCoach/getEmpresas", executeRef);
    }
  } catch (error) {
      console.error("Error deleting survey:", error);
  }
};  

// Modifica handleAplicarEncuesta para aceptar el contenido del correo
const handleAplicarEncuesta = async (startDate, endDate, reminderDate, selectedSurveyId, equipos, contenidoCorreo) => {
  setSuccess(false);

  startDate = new Date(startDate);
  endDate = new Date(endDate);
  reminderDate = new Date(reminderDate);

  // No ajustar las horas

  if (!(startDate instanceof Date) || isNaN(startDate.getTime())) {
    console.error("Invalid start date:", startDate);
    return;
  }

  if (!(endDate instanceof Date) || isNaN(endDate.getTime())) {
    console.error("Invalid end date:", endDate);
    return;
  }

  if (!(reminderDate instanceof Date) || isNaN(reminderDate.getTime())) {
    console.error("Invalid reminder date:", reminderDate);
    return;
  }

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = (`0${date.getMonth() + 1}`).slice(-2);
    const day = (`0${date.getDate()}`).slice(-2);
    return `${year}-${month}-${day}`;
  };
  const url = selectedSurveyId === 'no-encuesta' ? "/gestionEncuestas/crearEncuesta" : "/gestionEncuestas/editarEncuesta";
  const body = {
    id_encuesta: selectedSurveyId === 'no-encuesta' ? null : selectedSurveyId,
    id_empresa: selectedCompanyId,
    fechaInicio: formatDate(startDate),
    fechaFin: formatDate(endDate),
    fechaRecordatorio: formatDate(reminderDate),
    equipos: equipos.map(e => e.id_equipo),
    contenidoCorreo: contenidoCorreo,
  };

  try {
    const apiUrl = process.env.REACT_APP_API_URL || "https://api.systemicsurvey.com";
    const response = await execute3(`${apiUrl}${url}`, "POST", body);
    if (response && (response.status === 201 || response.status === 200)) {
      setSuccess(true);
      setOpenSurveyGroups((prev) => prev.filter(id => id !== selectedSurveyId)); // Cierra la pestaña actual
      setTimeout(() => {
        fetchApiData("/gestionCoach/getEmpresas", executeRef);
      }, 1000);

    }
  } catch (error) {
    console.error("Error updating survey:", error);
  }
};


  // Función para obtener el texto del botón de gestión de encuestas
  const getSurveyButtonText = () => {
    const selectedTeams = getSelectedTeams();
  
    if (selectedTeams.length === 0) {
      return t("createOrManageSurvey"); // Cuando no hay equipos seleccionados
    }
  
    const hasActiveOrScheduledSurvey = selectedTeams.some(team => {
      const surveyState = getEncuestaEstado(team.encuestas);
      return surveyState !== null;
    });
  
    if (hasActiveOrScheduledSurvey) {
      return t("manageSurveySend"); // Si al menos uno tiene encuesta
    }
  
    return t("createSurvey"); // Si ninguno tiene encuesta
  };
  

  const handleSendNow = async (surveyId) => {  
    setSuccesSendNow(false);
    try {
      const apiUrl = process.env.REACT_APP_API_URL || "https://api.systemicsurvey.com";
      const response = await execute4(`${apiUrl}/gestionCoach/enviarEncuestaAhora`, "POST", {id_encuesta: surveyId});
      if (response && response.status === 200) {
        console.log("Encuesta enviada con éxito");
        setSuccesSendNow(true);
        // Actualizar la lista de empresas
        setTimeout(() => {
          fetchApiData("/gestionCoach/getEmpresas", executeRef);
        }, 1000);

      }
    } catch (error) {
      console.error("Error enviando la encuesta:", error);
    }
  };
  

  return (
    <>
      <div className={mainStyle.main}>
        {error2 && <ErrorPopup message={error2} />}
        {success && <SuccessPopup message={t("operationSuccess")} onClose={() => setSuccess(false)} />}
        {successDelete && <SuccessPopup message={t("companyDeleted")} onClose={() => setSuccessDelete(false)} />}
        {successCreateTeam && <SuccessPopup message={t("teamCreated")} onClose={() => setSuccessCreateTeam(false)} />}
        {succesSendNow && <SuccessPopup message={t("surveySent")} onClose={() => setSuccesSendNow(false)} />}
        {infoSelectTeams && <InfoPopup message={t("necessarySelectTeams")} onClose={() => setInfoSelectTeams(false)} />}
        {error3 && <ErrorPopup message={error3} />}
        <SideBar selectedCompany={selectedCompany} />
        <div className={mainStyle.central} style={{ overflow: "scroll" }}>
          <div className={styles.titleButton}>
            <h1 className={styles.tituloEmpresas}>{t("listOfCompanies")}</h1>
            <div className={styles.empresasbuttons}>
            <ButtonPrincipal 
              onClick={handleGestionarEncuesta}
              style={{
                opacity: getSelectedTeams().length === 0 ? 0.5 : 1,
                cursor: getSelectedTeams().length === 0 ? 'default' : 'pointer'
              }}
            >
              {getSurveyButtonText()}
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368">
                  <path d="m787-145 28-28-75-75v-112h-40v128l87 87Zm-587 25q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v268q-19-9-39-15.5t-41-9.5v-243H200v560h242q3 22 9.5 42t15.5 38H200Zm0-120v40-560 243-3 280Zm80-40h163q3-21 9.5-41t14.5-39H280v80Zm0-160h244q32-30 71.5-50t84.5-27v-3H280v80Zm0-160h400v-80H280v80ZM720-40q-83 0-141.5-58.5T520-240q0-83 58.5-141.5T720-440q83 0 141.5 58.5T920-240q0 83-58.5 141.5T720-40Z"/>
                </svg>
              </ButtonPrincipal>
              <ButtonPrincipal onClick={() => setIsPopupOpen(true)}>
              {t("createCompany")}
              <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368">
                  <path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z"/>
                </svg>
              </ButtonPrincipal>
            </div>
          </div>
          {companies.map((company) => (
            <div key={company.id_empresa} className={styles.company}>
              <div className={styles.divTitle} onClick={() => toggleExpandCompany(company.id_empresa)}>
                <div className={styles.titleSVGdelete}>
                  <span className={styles.toggleIcon}>
                    {expandedCompanies[company.id_empresa] ? (
                      <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368">
                        <path d="M480-344 240-584l56-56 184 184 184-184 56 56-240 240Z"/>
                      </svg>
                    ) : (
                      <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368">
                        <path d="M504-480 320-664l56-56 240 240-240 240-56-56 184-184Z"/>
                      </svg>
                    )}
                  </span>
                  <h2 className={styles.companyName}>
                    {company.nombre}
                  </h2>
                </div>
                <div className={styles.divSVGs}>
                  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368" onClick={() => openCreateTeamPopup(company.id_empresa)}>
                    <path d="M440-440H200v-80h240v-240h80v240h240v80H520v240h-80v-240Z"/>
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e3147f" onClick={() => openDeletePopup(company)}>
                    <path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z"/>
                  </svg>
                </div>
              </div>
              {expandedCompanies[company.id_empresa] && (
                <div className={styles.equipos}>
                  {company.equipos && company.equipos.map((equipo) => {
                    const encuesta = getEncuestaEstado(equipo.encuestas);
                    return (
                      <div key={equipo.id_equipo} className={styles.equipo}>
                        <div className={styles.divInputLabel}>
                        <input 
                          type="checkbox" 
                          id={`equipo-${equipo.id_equipo}`} 
                          onChange={(e) => handleCheckboxChange(equipo.id_equipo, e.target.checked)}
                        />
                          <label htmlFor={`equipo-${equipo.id_equipo}`} className={styles.equipoLabel}>
                            <span className={styles.equipoName}>{equipo.nombre}</span>
                          </label>  
                        </div>
                        {encuesta ? (
                          <p className={styles.encuesta}>
                            {encuesta.estado === 'activa' && <span className={styles.encuestaActiva}>{t("activeSurvey")}</span>}
                            {encuesta.estado === 'programada' && <span className={styles.encuestaProgramada}>{t("scheduledSurvey")}</span>}
                            <span className={styles.encuestaFechas}>{encuesta.fechas}</span>
                          </p>
                        ) : null}
                        <div className={styles.divSVGs}>
                          <svg  xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368" onClick={() => handleEquipoClick(equipo.id_equipo)}>
                            <path d="M504-480 320-664l56-56 240 240-240 240-56-56 184-184Z"/>
                          </svg>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      {isModalOpen && (
        <CompanySelector setSelectedCompany={setSelectedCompany} setSelectedCompanyId={setSelectedCompanyId} companies={companies} />
      )}
      {isPopupOpen && (
        <CrearEmpresaPopup
          onClose={() => setIsPopupOpen(false)}
          onCreate={handleCreateEmpresa}
        />
      )}
      {isDeletePopupOpen && companyToDelete && (
        <EliminarEmpresaPopup
          onClose={() => setIsDeletePopupOpen(false)}
          onDelete={handleDeleteEmpresa}
          companyName={companyToDelete.nombre}
        />
      )}
      {isCreateTeamPopupOpen && companyIdForTeam && (
        <CrearEquipoPopup
          onClose={() => setIsCreateTeamPopupOpen(false)}
          onCreate={handleCreateEquipo}
          companyId={companyIdForTeam}
        />
      )}
      {isGestionEncuestaOpen && (
        <GestionEncuesta 
            onClose={() => setIsGestionEncuestaOpen(false)}
            onDelete={handleDeleteEncuesta}
            surveyGroups={surveyGroups} 
            onAplicar={handleAplicarEncuesta}
            openTabs={openSurveyGroups}
            setOpenTabs={setOpenSurveyGroups}
            sendNow={handleSendNow} // Aquí la función de envío
            />
      )}
    </>
  );
}

export default Empresas;