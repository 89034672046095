import React, { createContext, useState, useEffect } from 'react';

export const CompanyContext = createContext();

export const CompanyProvider = ({ children }) => {
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [selectedCompanyId, setSelectedCompanyId] = useState(null);

    useEffect(() => {
        const savedCompany = sessionStorage.getItem("selectedCompany");
        const savedCompanyId = sessionStorage.getItem("selectedCompanyId");
        if (savedCompany && savedCompanyId) {
            setSelectedCompany(savedCompany);
            setSelectedCompanyId(savedCompanyId);
        }
    }, []);

    useEffect(() => {
        if (selectedCompany && selectedCompanyId) {
            sessionStorage.setItem("selectedCompany", selectedCompany);
            sessionStorage.setItem("selectedCompanyId", selectedCompanyId);
        } else {
            sessionStorage.removeItem("selectedCompany");
            sessionStorage.removeItem("selectedCompanyId");
        }
    }, [selectedCompany, selectedCompanyId]);
    

    return (
        <CompanyContext.Provider value={{ selectedCompany, selectedCompanyId, setSelectedCompany, setSelectedCompanyId }}>
            {children}
        </CompanyContext.Provider>
    );
};
