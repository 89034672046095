import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchUserInfo, refreshToken } from "./features/userSlice";
import RoutesComponent from "./routes/routes";
import { CompanyProvider } from './features/CompanyContext';

function App() {
  const dispatch = useDispatch();
  const [isLogged, setIsLogged] = useState(false);

  useEffect(() => {
    dispatch(refreshToken())
      .unwrap()
      .then((payload) => {
        setIsLogged(true);
        dispatch(fetchUserInfo());
      })
      .catch((error) => {
        setIsLogged(false);
      })
  }, [dispatch]);

  return (
      <div className="App">
        <CompanyProvider>
          <RoutesComponent shouldRedirect={isLogged} />
        </CompanyProvider>
      </div>
  );
}

export default App;
