import React from "react";
import mainStyle from "../styles/mainComponents.module.css";

function Button({ disabled, style, children, onClick, type = "button" }) {
  return (
    <button
      className={mainStyle.btnSecundario}
      onClick={onClick}
      type={type}
      disabled={disabled}
      style={style}
    >
      {children}
    </button>
  );
}

export default Button;
