/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import LoginForm from "../components//authComponents/LoginForm";
import useFetch from "../hooks/useFetch";
import mainStyle from "../styles/mainComponents.module.css";
import loginStyle from "../styles/login.module.css";
import Loader from "../components/Loader";
import { ErrorPopup } from "../components/Popup";
import { useNavigate, Link } from "react-router-dom";  // Importamos Link para el enlace
import { useDispatch, useSelector } from "react-redux";
import { fetchUserInfo } from "../features/userSlice";
import { useTranslation } from "react-i18next";

function Login() {
  const [localLoading, setLocalLoading] = useState(false);
  const { error, execute } = useFetch();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isChecked = useSelector((state) => state.user.isChecked);
  const { t } = useTranslation();

  const handleLogin = async ({ email, password }) => {
    setLocalLoading(true);
    try {
      const apiUrl = process.env.REACT_APP_API_URL || "https://api.systemicsurvey.com";
      const response = await execute(`${apiUrl}/auth/login`, "POST", {email, password, rememberMe: isChecked});
      if (response && response.status === 200) {
        await dispatch(fetchUserInfo()).unwrap();
        navigate("/home");
      }
    } catch (e) {
      console.error("Error durante el inicio de sesión:", e);
    } finally {
      setLocalLoading(false);
    }
  };

  return (
    <>
      <div className={loginStyle.general}>
        <div className={loginStyle.registerRight}>
          {localLoading && <Loader />} 
          {error && <ErrorPopup message={t("errorLogin")} />}
          <div className={`${loginStyle.registerDiv} ${mainStyle.container}`}>
            <img className={loginStyle.logoi8} src="https://www.8incompany.com/wp-content/uploads/2023/09/logo-8INCOMPANY-e1695725322150.png" alt="" />
            <h1 className={loginStyle.formTittle}>{t("singin")}</h1>
            <LoginForm onSubmit={handleLogin} />
            {/* Aquí añadimos el enlace para recuperar la contraseña */}
            <div className={loginStyle.recoverPassword}>
              <Link to="/recover-password" className={loginStyle.recoverLink}>
                {t("forgotPassword")}
              </Link>
            </div>
          </div>
          <div className={mainStyle.logofeel}>
            <p>Powered by</p>
            <img className={mainStyle.logoLogin} src="/img/logo_largo.png" alt="Logo" />
          </div>
        </div>
        <div className={`${loginStyle.registerLeft} ${mainStyle.container}`}>
          <img src="/img/preview.png" width="500px" alt="" />
        </div>
      </div>
    </>
  );
}

export default Login;
