import React, { useState } from "react";
import useFetch from "../hooks/useFetch";
import mainStyle from "../styles/mainComponents.module.css";
import loginStyle from "../styles/login.module.css";
import Loader from "../components/Loader";
import { ErrorPopup, SuccessPopup } from "../components/Popup";
import { useTranslation } from "react-i18next";
import ButtonPrincipal from "../components/ButtonPrincipal";

function PasswordRecovery() {
  const [email, setEmail] = useState("");
  const [localLoading, setLocalLoading] = useState(false);
  const { error, execute } = useFetch();
  const [succesEmail, setSuccesEmail] = useState(false);
  const { t } = useTranslation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLocalLoading(true);
    try {
      const apiUrl = process.env.REACT_APP_API_URL || "https://api.systemicsurvey.com";
      const response = await execute(`${apiUrl}/gestionCoach/requestPasswordReset`, "POST", { email });
      if (response && response.status === 200) {
        setSuccesEmail(true);
      }
    } catch (e) {
      console.error("Error enviando solicitud de recuperación de contraseña:", e);
    } finally {
      setLocalLoading(false);
    }
  };

  return (
    <>
      <div className={loginStyle.general}>
        <div className={loginStyle.registerRight}>
          {localLoading && <Loader />}{" "}
          {error && <ErrorPopup message={t("errorPasswordRecovery")} />}
          {succesEmail && <SuccessPopup message={t("successPasswordRecovery")} />}
          <div className={`${loginStyle.registerDiv} ${mainStyle.container}`}>
            <img
              className={loginStyle.logoi8}
              src="https://www.8incompany.com/wp-content/uploads/2023/09/logo-8INCOMPANY-e1695725322150.png"
              alt="Logo"
            />
            <h1 className={loginStyle.formTittle}>{t("recoverPasswordTitle")}</h1>
            <form onSubmit={handleSubmit} className={loginStyle.contenedorLogin}>
              <div className={loginStyle.formgroup}>
                <label htmlFor="email" className={loginStyle.formText}>{t("email")}</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className={loginStyle.inputRecovery}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <ButtonPrincipal type="submit" className={loginStyle.btnSubmit}>
                {t("recoverPassword")}
              </ButtonPrincipal>
            </form>
          </div>
          <div className={mainStyle.logofeel}>
            <p>Powered by</p>
            <img className={mainStyle.logoLogin} src="/img/logo_largo.png" alt="Logo" />
          </div>
        </div>
        <div className={`${loginStyle.registerLeft} ${mainStyle.container}`}>
          <img src="/img/preview.png" width="500px" alt="" />
        </div>
      </div>
    </>
  );
}

export default PasswordRecovery;
