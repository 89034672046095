import "./styles/reset.css";
import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { Provider as ReduxProvider } from "react-redux";
import store from "./store/store";
import "../src/features/i18n";

const root = document.getElementById("root");
const reactRoot = createRoot(root);

reactRoot.render(
  <ReduxProvider store={store}>
    <App />
  </ReduxProvider>,
);
